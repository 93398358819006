<template>
     <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="CityList"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      <div class="flex  justify-between">

        <vs-button type="border" icon-pack="feather" @click="AddCity"
          >{{ $t("AddCity") }} </vs-button
        >

      </div>
        <!-- <vs-button type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">Add New</vs-button> -->

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <!-- <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                CityList.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : CityList.length
              }}
              of {{ CityList.length }}</span
            > -->
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!--  <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="City">{{ $t("CityNameAr") }}</vs-th>
        <vs-th sort-key="City">{{ $t("CityNameEn") }}</vs-th>
        <vs-th sort-key="State">{{ $t("StateNameAr") }}</vs-th>
        <vs-th sort-key="State">{{ $t("StateNameEn") }}</vs-th>
        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>

       <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
                <p class="product-name font-medium truncate">{{ tr.admin2Name_ar }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.admin2Name_en }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.admin1Name_ar }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.admin1Name_en }}
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
             <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
             <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="openConfirm(tr)" />
              <!-- <router-link
                :to="{
                  name: 'AddCity',
                  params: { Id: tr.Id },
                }"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              >
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  style="padding: 2px;"
                />
              </router-link> -->
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>



<script>
import moduleCountry from "@/store/countrySetting/moduleCountry.js";

export default {
  data() {
    currentPage: 0
    return {
      config: {
        dateFormat: "Y-m-d H:i ",
        enableTime: true
      },
      instructors: [],
      courseModules: [],
      StateList: [],
      CityList: [],
      Providers: [],
      selected: [],
      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      itemsPerPage: 10,
      isMounted: false,
      Model: {},
      ApiModel: {},
      country_not_found: false,
      alpha2Code: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.Model.Flag != undefined &&
        this.Model.Country != undefined &&
        this.Model.CountryCode != undefined &&
        this.Model.SupportPhoneNo != ""
      );
    },

     Country() {
      return this.$store.state.CountryList.Country;
    },
     StateWithLocale() {
      let State = "admin1Name_en";
      if (this.$i18n.locale == "ar") {
        State = "admin1Name_en";
      }
      return State;
    },

     StateWithLocaleen() {
      let State = "admin1Name_ar";
      if (this.$i18n.locale == "ar") {
        State = "admin1Name_ar";
      }
      return State;
    },

  },

  methods: {
    editData(data) {
    debugger;
      // this.sidebarData = data;
      // this.toggleDataSidebar(true);
      const OBJECTID = data.OBJECTID;
if (OBJECTID ==null) {
  OBJECTID=0;
 }
      this.$router.push({

              name: "AddCity",
              params: { OBJECTID: OBJECTID }
            });
      // this.$router.push(

      // // 'AddCity' , OBJECTID
      // );
    },
    deleteData(data) {
      debugger;
      this.$store.dispatch("CountryList/DeleteCity", data)
      .then(() => {
        this.$router.go();
        }
      )
      .catch(() => {});
      this.cancel();
      this.$store
      .dispatch("CountryList/GetCities")
    },
    cancel() {
      this.$router.push("/City");
    },

    // deleteData(data) {
    //   debugger;
    //   this.$store.dispatch("CountryList/DeleteCity", data)
    //   .then(() => {
    //       this.cancel();
    //     }
    //   )
    //   .catch(() => {});
    //   this.cancel();
    //   this.$store
    //   .dispatch("CountryList/GetCities")
    // },
    // cancel() {
    //   this.$router.push("/City");
    // },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
        debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Areyousureyouwantdeletethisrecord"),
        accept: this.acceptAlert
      });
    },
    toggleSearchSidebar(val) {
      this.showSearchSidebar = val;
    },

    acceptAlert() {
        debugger;
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "danger",
        title: this.$t("Deletedrecord"),
        text: this.$t("The selected record was successfully deleted")
      });
    },

    AddCity() {
      const OBJECTID=0;
      this.$router.push({

        name: "AddCity",
        params: { OBJECTID: OBJECTID }
      });
    },

    //  save_changes(){
    //   debugger;
    //     this.Model.admin0Name_en="Yemen";
    //       this.Model.admin0Name_ar="اليمن";
    //         this.Model.admin0Pcode="YE";
    //           this.Model.Shape_Length="0.2916760000000000";
    //             this.Model.Shape_Area="0.004992";
    //              this.Model.Shape="Polygon";
    //             this.Model.admin1Pcode="YE";
    //             //this.Model.OBJECTID="161.0";
    //                this.Model.admin2Pcode="YE";
    //   this.$store
    //     .dispatch("CountryList/addCity", this.Model)
    //     .then((response) => {
    //                if (response.status == 200) {
    //         this.$vs.loading.close();
    //         window.showSuccessCreate();
    //         this.$router.push({
    //           name: "FleetCompaniesDetails",
    //           params: { Id: response.data }
    //         });
    //       }

    //     })
    //      .catch(() => {
    //       window.showError();
    //     });
    //  }
  },

    created() {
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    var Model = {};
    Model.CountryCode = "YE";

    this.$store
      .dispatch("CountryList/GetCities")
      .then(res => {
        debugger;
        this.CityList = res.data;
      })
      .catch(() => {});
    this.$store.dispatch("CountryList/fetchAllCountriesList").then(() => {
      this.Country.forEach(element => {
        if (
          element.CountryCode ==
          JSON.parse(localStorage.getItem("userInfo")).countrycode
        ) {
          this.Model.CountryCode = JSON.parse(
            localStorage.getItem("userInfo")
          ).countrycode;
        }
      });
    });
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          .product-img {
            height: 70px;
            width: 100px;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
